<template>
  <a-row class="ministry">
    <a-row class="content-title" type="flex">
      <h2 class="flex-1">
        {{ $route.meta.title[$i18n.locale] }}
      </h2>

      <a-row
        class="flex-no__wrap ml-auto wanted-search-input-wrapper"
        type="flex"
        style="justify-content: flex-end"
      />
    </a-row>
    <a-table
      :key="$route.fullPath"
      :columns="columns"
      :data-source="tableProps"
    >
      <template slot="name" slot-scope="item">
        <div
          style="cursor: pointer"
          @click="
            $router.push({
              name: item.name,
              params: { type: item.type }
            })
          "
        >
          {{ item.title[$i18n.locale] }}
        </div>
      </template>
    </a-table>
  </a-row>
</template>

<script>
// import searchInput from "@/components/wanted/search-input"

const columns = [
  {
    title: "Название",
    key: "title",
    class: "wanted-fio-td",
    scopedSlots: { customRender: "name" }
  }
]

export default {
  data: () => ({
    tableProps: [
      {
        title: {
          oz: "Асосий йўналиш",
          uz: "Asosiy yo'nalish",
          qr: "Tiykarǵı jónelis",
          ru: "Основные направление",
          en: "Main direction"
        },
        name: "main-links"
      },
      {
        title: {
          oz: "Марказий аппарат",
          uz: "Markaziy apparat",
          qr: "Центральный аппарат",
          ru: "Центральный аппарат",
          en: "Headquarters"
        },
        type: 0,
        name: "headquarters"
      },
      {
        title: {
          oz: "Вазирлик тассаруфидаги ташкилотлар",
          uz: "Vazirlik tassarufidagi tashkilotlar",
          qr: "Подведомственные организации",
          ru: "Подведомственные организации",
          en: "Subordinated organizations"
        },
        type: 1,
        name: "headquarters"
      },
      {
        title: {
          oz: "Раҳбарлар",
          uz: "Rahbarlar",
          qr: "Basshılar",
          ru: "Руководители",
          en: "Leadership"
        },
        name: "activityList"
      }
      // {
      //   title: {
      //     oz: "Департамент маълумотлари",
      //     uz: "Departament ma'lumotlari",
      //     qr: "Информация об отделе",
      //     ru: "Информация об отделе",
      //     en: "Информация об отделе"
      //   },
      //   name: "aboutAct"
      // }
    ],
    columns
  }),
  methods: {}
}
</script>
<style>
.wanted-search-input-wrapper {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 430px;
  flex: 0 0 430px;
  justify-content: space-around;
}
/*.ministry .ant-table-pagination.ant-pagination {
  display: none;
}*/
</style>
